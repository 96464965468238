
import { defineComponent, ref, watch } from 'vue'
import YearPicker from '@/views/Payment/components/YearPicker.vue'
import { Moment } from 'moment'
import moment from 'moment'
import useModel from '@/hooks/useModel'
import { getPlanningYear } from '@/API/planning'
import type { PlanningYearData } from '@/views/Planning/type'
export default defineComponent({
  emits: [
    'update:visible',
    'confirm',
  ],
  props: {
    make: {
      type: String
    }
  },
  components: {
    YearPicker
  },
  setup(props, {emit}) {
    
    const visibleModel = useModel(props, 'visible')

    const planningYears = ref([])

    const currentYear = ref<Moment>(moment())

    const selectedPlanningScenario = ref<PlanningYearData>()

    const handleClose = () => {
      visibleModel.value = false
    }

    const columns = ref([
      {
        title: 'Planning Scenario',
        dataIndex: 'planningName'
      },
      {
        title: 'Final Kufri Version',
        dataIndex: 'currentKufri'
      },
      {
        title: 'Create Time',
        slots: {customRender: 'CreateTime'},
      }
    ])

    watch([() => props.make, () => currentYear.value.format('YYYY')], async ([make, year]) => {
      planningYears.value = (await getPlanningYear({
        make,
        currentYear: year
      })).data.filter((item: PlanningYearData) => item.currentKufri === item.kufriMonth && item.lockStatus === true)
      selectedPlanningScenario.value = undefined
    }, {
      immediate: true
    })

    const changePlanningScenario = (selectRowKeys: string[], selectedRows: PlanningYearData[]) => {
      if (selectRowKeys.length) {
        selectedPlanningScenario.value = selectedRows[0]
      }
    }

    const handleConfirm = () => {
      emit('confirm', selectedPlanningScenario.value)
      handleClose()
    }

    return {
      visibleModel,
      moment,
      currentYear,
      handleClose,
      columns,
      planningYears,
      selectedPlanningScenario,
      changePlanningScenario,
      handleConfirm
    }
  }
})
