
import { defineComponent, ref, reactive, watch, computed } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import { message } from 'ant-design-vue'
import { Modal } from 'ant-design-vue'
import SpreadJsTable from '@/views/Planning/components/SpreadJsTable.vue'
import { FormatPercentage } from '@/views/Planning/components/SpreadJsTable.vue'
import { monthes, monthesEn, monthEnToNumber } from '@/utils/planning'
import ScenarioSelector from './ScenarioSelector.vue'
import { getGuideLineConfig, saveGuideLineConfig, getGuideLineDataList, saveGuideLineData } from '@/API/budget'
import { getBuList } from '@/API/approvalChain'
import { findBuVehicleList } from '@/API/approve'
import type { GuideLineConfig, GuideLineData, GuideLineSpreadJsData, GuideLineDataParam } from '@/views/Approve/ControllingRoleApprove/ProgramHome/types'
import type { PlanningYearData } from '@/views/Planning/type'
import type { Columns } from '@/views/Planning/components/SpreadJsTable.vue'
import GC from '@grapecity/spread-sheets'

enum Mode {
  VIEW = 'Update',
  EDIT = 'SAVE'
}

export default defineComponent({
  emits: [
    'save-data'
  ],
  components: {
    SpreadJsTable,
    ScenarioSelector
  },
  setup(props, { emit }) {
    const mode = ref(Mode.VIEW)
    const scenarioMake = ref('CBU')
    const guideLineConfig = reactive(new Map<string, GuideLineConfig>())

    const queryParams = reactive({
      make: '',
      buId: '',
      brandList: [],
      typeClassList: []
    })

    let firstLevelBuIds: number[] = []

    const conditionState = reactive({
      make: '',
      buId: '',
      kufriVersion: undefined as string | undefined,
      year: undefined as string | undefined,
      planningId: undefined as number | undefined,
    })

    const actualStatus = computed(() => {
      const actualStatusMap = new Map(monthes.map((item: string) => [item, false]))
      const kufriVersion = conditionState.kufriVersion
      if (kufriVersion) {
        const matches = kufriVersion.match(/(\d{4})K(\d{2})/)
        if (matches && matches.length > 2) {
          const kufriYear = matches[1]
          const kufriMonth = matches[2]
          for (let i = 0; i < 12; i++) {
            if (conditionState.year && (conditionState.year < kufriYear ||
              (conditionState.year === kufriYear && monthes[i] < kufriMonth))) {
              actualStatusMap.set(monthes[i], true)
            }
          }
        }
      }
      return actualStatusMap
    })

    const tableColumns = ref<Columns>([])

    const tableData = ref<GuideLineSpreadJsData[]>([])

    const expandModel = ref(false)

    const expandRows = computed(() => {
      return tableData.value.reduce((rows: number[], item, index) => {
        if (item.level === 0) {
          rows.push(index)
        }
        if (expandModel.value && item.level === 1) {
          rows.push(index)
        }
        return rows
      }, [])
    })

    getGuideLineConfig().then((config: Record<string, GuideLineConfig>) => {
      for (const key in config) {
        guideLineConfig.set(key, config[key])
      }
    })

    const scenarioSelectorVisible = ref(false)

    const handleToSelectScenario = (make: string) => {
      scenarioMake.value = make
      scenarioSelectorVisible.value = true
    }

    const handleSelectScenario = async (planningYearData: PlanningYearData) => {
      await saveGuideLineConfig({
        currentYear: planningYearData.createYear,
        id: guideLineConfig.get(scenarioMake.value)?.id ?? 0,
        kufriVersion: planningYearData.currentKufri,
        make: planningYearData.make,
        planningId: planningYearData.id,
        planningName: planningYearData.planningName,
        year: planningYearData.year
      })
      guideLineConfig.clear()
      const config = await getGuideLineConfig()
      for (const key in config) {
        guideLineConfig.set(key, config[key])
      }
    }

    const makes = ref<{id: string; name: string}[]>([])
    const buList = ref<{id: string; name: string}[]>([])
    const brandList = ref<{id: string; name: string}[]>([])
    const typeClassList = ref<{id: string; name: string}[]>([])

    const initMake = async () => {
      makes.value = await findBuVehicleList({target: 'make'})
      queryParams.make = makes.value?.[0].name ?? ''
    }

    watch(() => queryParams.make, async (make) => {
      buList.value = (await findBuVehicleList({
        make: [make],
        target: 'buId'
      })).filter(bu => firstLevelBuIds.includes(parseInt(bu.id)) && bu.name !== 'Companycar')
      const buMBPC = buList.value.find(bu => bu.name === 'MBPC')
      queryParams.buId = buMBPC ? buMBPC.id : (buList.value?.[0].id ?? '')
    })

    watch(() => queryParams.buId, async (buId) => {
      brandList.value = await findBuVehicleList({
        make: [queryParams.make],
        buId: [buId],
        target: 'brand'
      })
      queryParams.brandList = []
    })

    watch(() => queryParams.brandList, async (brands) => {
      if (brands.length) {
        typeClassList.value = await findBuVehicleList({
          make: [queryParams.make],
          buId: [queryParams.buId],
          brand: brands,
          target: 'typeClass'
        })
      } else {
        typeClassList.value = []
      }
      queryParams.typeClassList = []
    }, {
      deep: true
    })

    const cellRender = (row: number, column: string, cell: GC.Spread.Sheets.CellRange) => {
      const rowData = tableData.value?.[row]
      if (!rowData) {
        return
      }
      if (['brand', 'typeClass', 'nstGroupName'].includes(column)) {
        if (column === 'typeClass' && row === 0) {
          cell.font('700 11pt Calibri')
        }
        return
      }
      let columnName: string|undefined = undefined
      if (column.indexOf('finalPoint')) {
        columnName = column.replace('.finalPoint', '')
      }
      if (!columnName || !rowData[columnName]) {
        return
      }
      if (rowData[columnName].editable) {
        if ((!rowData[columnName].dataType && rowData[columnName].finalPoint)
          || rowData[columnName].finalPoint) {
          cell.backColor('#DDEBF7')
        } else if (!rowData[columnName].finalPoint && rowData[columnName].todoVolume) {
          cell.backColor('#FFFFCC')
        }
      } else {
        cell.backColor('#CCCCCC')
        cell.locked(true)
      }
    }

    const handleValueChanged = (event: any, data: any) => {
      const columns = [undefined, undefined, undefined, undefined, ...monthesEn]
      const rowData = tableData.value?.[data.row]
      if (!rowData || data.newValue === data.oldValue) {
        return
      }
      const columnName = columns[data.col]
      if (!columnName) {
        return
      }
      data.sheet.suspendPaint()
      rowData[columnName].finalPoint = data.newValue ?? ''
      if ((rowData[columnName].updatedAt !== rowData[columnName].createdAt
          || rowData[columnName].finalPoint !== rowData[columnName].originValue)
        && rowData[columnName].finalPoint) {
        data.sheet.getCell(data.row, data.col).backColor('#DDEBF7')
      } else if (!rowData[columnName].finalPoint && rowData[columnName].todoVolume) {
        data.sheet.getCell(data.row, data.col).backColor('#FFFFCC')
      } else {
        data.sheet.getCell(data.row, data.col).backColor('#FFFFFF')
      }
      data.sheet.resumePaint()
    }

    const handleEditStarting = (event: any, data: any) => {
      if (mode.value === Mode.VIEW) {
        data.cancel = true
      }
    }

    const handleClipboardPasting = (event: any, data: any) => {
      if (mode.value === Mode.VIEW) {
        data.cancel = true
        return
      }
      const columns = [undefined, undefined, undefined, undefined, ...monthesEn]
      const pasteData = data.pasteData.text.replace(/\r\n$/, '').split('\r\n').map((item: string) => item.split('\t'))
      const { row, rowCount, col, colCount } = data.cellRange
      data.sheet.suspendPaint()
      for (let i = 0, visiableRowIndex = 0; i < rowCount; i++) {
        if (!data.sheet.rowOutlines.isCollapsed(row + i)) {
          const rowData = tableData.value[row + i]
          if (rowData.level === 0) {
            continue
          }
          for (let j = 0; j < colCount; j++) {
            const column = columns[col + j]
            if (!column) {
              continue
            }

            let finalPoint = pasteData[visiableRowIndex][j]
            if (finalPoint !== '') {
              if (finalPoint[finalPoint.length - 1] === '%') {
                finalPoint = parseFloat(finalPoint.replace('%', ''))
              } else {
                finalPoint = parseFloat(finalPoint) * 100
              }
              finalPoint = isNaN(finalPoint) ? '' : finalPoint.toString()
            }

            rowData[column].finalPoint = finalPoint
            if ((rowData[column].updatedAt !== rowData[column].createdAt
                || rowData[column].finalPoint !== rowData[column].originValue)
              && rowData[column].finalPoint) {
              data.sheet.getCell(row + i, col + j).backColor('#DDEBF7')
            } else if (!rowData[column].finalPoint && rowData[column].todoVolume) {
              data.sheet.getCell(row + i, col + j).backColor('#FFFFCC')
            } else {
              data.sheet.getCell(row + i, col + j).backColor('#FFFFFF')
            }
          }
          visiableRowIndex++
        }
      }
      data.cancel = true
      data.sheet.resumePaint()
    }

    const handleRangeChanged = (event: any, data: any) => {
      if (mode.value === Mode.VIEW) {
        data.cancel = true
        return
      }
      if (data.action === GC.Spread.Sheets.RangeChangedAction.clear) {
        const columns = [undefined, undefined, undefined, undefined, ...monthesEn]
        data.sheet.suspendPaint()
        for (let row = data.row; row < data.row + data.rowCount; row++) {
          for (let col = data.col; col < data.col + data.colCount; col++) {
            const rowData = tableData.value?.[row]
            if (!rowData || data.oldValue === '') {
              continue
            }
            const column = columns?.[col]
            if (!column) {
              continue
            }
            rowData[column].finalPoint = ''
            if ((rowData[column].updatedAt !== rowData[column].createdAt
                || rowData[column].finalPoint !== rowData[column].originValue)
              && rowData[column].finalPoint) {
              data.sheet.getCell(row, col).backColor('#DDEBF7')
            } else if (!rowData[column].finalPoint && rowData[column].todoVolume) {
              data.sheet.getCell(row, col).backColor('#FFFFCC')
            } else {
              data.sheet.getCell(row, col).backColor('#FFFFFF')
            }
          }
        }
        data.sheet.resumePaint()
      }
    }

    const handleSearch = async () => {
      const data = await getGuideLineDataList(queryParams.make, queryParams.buId, queryParams.brandList,
        queryParams.typeClassList)
      conditionState.make = queryParams.make
      conditionState.buId = queryParams.buId
      conditionState.kufriVersion = guideLineConfig.get(queryParams.make)?.kufriVersion
      conditionState.year = guideLineConfig.get(queryParams.make)?.year
      conditionState.planningId = guideLineConfig.get(queryParams.make)?.planningId

      const buName = buList.value.find((bu: any) => bu.id === queryParams.buId)?.name ?? ''

      tableData.value = []
      data.forEach((typeClass: GuideLineData) => {
        const typeClassData = {
          level: 0,
          brand: typeClass.brand,
          bu: typeClass.bu,
          typeClass: typeClass.typeClass,
          nstGroup: typeClass.nstGroup,
          nstGroupName: '',
          annual: typeClass.annual,
          ...monthesEn.reduce((result, monthEn: string) => {
            result[monthEn] = {
              ...typeClass[monthEn] ?? { finalPoint: '' },
              editable: false
            }
            result[monthEn].finalPoint = result[monthEn].finalPoint ?? ''
            result[monthEn].originValue = result[monthEn].finalPoint
            return result
          }, {}),
        } as GuideLineSpreadJsData
        tableData.value.push(typeClassData)
        const parentRow = tableData.value.length - 1
        const modelChildren: number[] = []
        typeClass.children?.forEach((model: GuideLineData) => {
          const modelData = {
            level: 1,
            brand: '',
            bu: model.bu,
            typeClass: '',
            nstGroup: model.nstGroup,
            nstGroupName: model.nstGroupName,
            kufriVersion: model.kufriVersion,
            annual: model.annual,
            ...monthesEn.reduce((result, monthEn: string) => {
              result[monthEn] = {
                ...model[monthEn] ?? { finalPoint: '' },
                editable: !model[monthEn]?.dataType && !actualStatus.value.get(monthEnToNumber(monthEn) as string)
              }
              result[monthEn].finalPoint = result[monthEn].finalPoint ?? ''
              result[monthEn].originValue = result[monthEn].finalPoint
              return result
            }, {}),
            parentRow: parentRow
          } as GuideLineSpreadJsData
          tableData.value.push(modelData)
          const typeClassRow = tableData.value.length - 1
          modelChildren.push(typeClassRow)
        })
        typeClassData.childrenRow = modelChildren
      })

      tableColumns.value = [
        {
          title: '',
          children: [
            {
              title: 'Brand',
              dataIndex: 'brand',
              width: 100,
              locked: true,
              align: 'left',
              fixed: 'left'
            },
            {
              title: 'Type class',
              dataIndex: 'typeClass',
              width: 100,
              locked: true,
              align: 'left',
              fixed: 'left'
            },
            {
              title: ['CPO', 'Fleet'].includes(buName) ? 'Model(NST Group)' : 'Model',
              dataIndex: 'nstGroupName',
              width: 300,
              locked: true,
              align: 'left',
              fixed: 'left'
            }
          ]
        },
        {
          title: '',
          children: [
            {
              title: `${conditionState.year}FY`,
              dataIndex: 'annual.finalPoint',
              align: 'right',
              width: 90,
              formatter: new FormatPercentage(),
              locked: true,
              style: {
                backColor: '#CCCCCC',
                cellPadding: '0 5'
              }
            }
          ]
        },
        // 月份列
        ...monthesEn.map((month, index, monthes) => {
          return {
            title: actualStatus.value.get(monthEnToNumber(month) as string) ? 'Actual' : 'Forecast',
            children: [
              {
                title: `${conditionState.year}-${monthEnToNumber(month)}`,
                dataIndex: `${month}.finalPoint`,
                align: 'right' as const,
                width: 90,
                formatter: new FormatPercentage(),
                locked: actualStatus.value.get(monthEnToNumber(month) as string),
                style: actualStatus.value.get(monthEnToNumber(month) as string) ? {
                  backColor: '#CCCCCC',
                  cellPadding: '0 5'
                } : {
                  cellPadding: '0 5'
                },
              }
            ]
          }
        })
      ]
    }

    const getChangedData = () => {
      const data = [] as GuideLineDataParam[]
      tableData.value.forEach(item => {
        if (item.level === 1) {
          monthesEn.forEach((monthEn: string) => {
            if (item[monthEn].finalPoint !==  item[monthEn].originValue) {
              data.push({
                id: item[monthEn].id,
                brand: tableData.value[item.parentRow as number].brand,
                bu: tableData.value[item.parentRow as number].bu,
                buId: parseInt(conditionState.buId),
                finalPoint: item[monthEn].finalPoint !== '' ? parseFloat(item[monthEn].finalPoint) : null,
                kufriVersion: item.kufriVersion as string,
                make: conditionState.make,
                month: monthEnToNumber(monthEn, true) as string,
                nstGroup: item.nstGroup as string,
                nstGroupName: item.nstGroupName as string,
                planningId: conditionState.planningId as number,
                rtLlp: item[monthEn].rtLlp,
                rtVolume: item[monthEn].rtVolume,
                todoVolume: item[monthEn].todoVolume,
                typeClass: tableData.value[item.parentRow as number].typeClass as string,
                wsLlp: item[monthEn].wsLlp,
                wsVolume: item[monthEn].wsVolume,
                year: item[monthEn].year ?? conditionState.year,
                dataType: false
              })
            }
          })
        }
      })
      return data
    }

    const handleSave = async () => {
      if (mode.value === Mode.VIEW) {
        mode.value = Mode.EDIT
        return
      }
      const data = getChangedData()
      if (data.length) {
        await saveGuideLineData(data)
        message.success('Save successful')
        emit('save-data')
        handleSearch()
      }
    }

    onBeforeRouteLeave((to, from, next) => {
      const data = getChangedData()
      if (data.length) {
        Modal.confirm({
          title: 'Note',
          content: 'Save before leave current page?',
          async onOk() {
            await handleSave()
            next()
          },
          onCancel() {
            next()
          }
        })
      } else {
        next()
      }
    })

    const init = async () => {
      firstLevelBuIds = (await getBuList() as any[]).filter(bu => bu.parentId === null).map(bu => bu.id)
      await initMake()
    }

    init()

    return {
      Mode,
      mode,
      scenarioMake,
      guideLineConfig,
      tableColumns,
      tableData,
      expandModel,
      expandRows,
      scenarioSelectorVisible,
      handleToSelectScenario,
      handleSelectScenario,
      queryParams,
      makes,
      buList,
      brandList,
      typeClassList,
      cellRender,
      handleValueChanged,
      handleEditStarting,
      handleClipboardPasting,
      handleRangeChanged,
      handleSearch,
      handleSave
    }
  }
})
